import { useLocation, Link } from 'react-router-dom';

export default function MenuLink(props) {
  const location = useLocation();

  return (
    <li
      className={`nav-item ${
        props.activeFilter
          ? location.pathname.startsWith(props.activeFilter)
            ? 'active'
            : ''
          : location.pathname === props.href
          ? 'active'
          : ''
      }`}
    >
      <Link
        to={props.href}
        exact
        className="nav-link"
        {...props}
        onClick={() => {
          props.toggleSidebar(false);
        }}
      >
        {props.children}
      </Link>
    </li>
  );
}
