import { ErrorMessage, Field, Form, Formik } from 'formik';

import CreatableSelect from 'react-select/creatable';

import Axios from '../../services/Axios';
import Button from '../ui/Button';
import FormError from '../ui/FormError';
import FormInput from '../ui/FormInput';
import FormInputGroup from '../ui/FormInputGroup';
import FormLabel from '../ui/FormLabel';

import Modal from './index';
import toast from 'react-hot-toast';
import { mutate } from 'swr';

import { useCompanyTypes } from '../../data';

export default function Component(props) {
  const { companyTypes, companyTypesLoading, companyTypesError } =
    useCompanyTypes();

  const SelectField = ({ field, colors, form, ...props }) => {
    return (
      <CreatableSelect
        noOptionsMessage={() => 'Nothing found'}
        onChange={async (newValue, actionMeta) => {
          if (actionMeta.action === 'clear') {
            await mutate(`${process.env.REACT_APP_API_URL}/api/company-types`);
          } else if (actionMeta.action === 'create-option') {
            try {
              const type = await Axios.post('api/company-types', {
                name: newValue.value,
              });

              form.setFieldValue('companyTypeId', type.data.data.id);
            } catch (error) {}
          } else {
            form.setFieldValue('companyTypeId', newValue.value);
          }
        }}
        {...props}
      />
    );
  };

  return (
    <>
      {!companyTypesLoading && !companyTypesLoading && (
        <Modal {...props}>
          <Formik
            initialValues={{ name: '' }}
            validate={(values) => {
              const errors = {};

              if (!values.name) {
                errors.name = 'This field is required';
              }

              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                await Axios.post('api/company', {
                  name: values.name,
                  companyTypeId: values.companyTypeId,
                });

                toast.success(`Company ${values.name} added`);

                props.onRequestClose();
              } catch (error) {}
            }}
          >
            {({ isSubmitting, isValid, dirty }) => (
              <Form>
                <FormInputGroup>
                  <FormLabel>Company name</FormLabel>
                  <Field
                    type="text"
                    name="name"
                    autoFocus
                    component={FormInput}
                  />
                  <ErrorMessage name="name" component={FormError} />
                </FormInputGroup>

                <FormInputGroup>
                  <FormLabel>Sort of company</FormLabel>

                  <Field
                    component={SelectField}
                    isClearable
                    options={companyTypes.map(({ id, name }) => ({
                      value: id,
                      label: name,
                    }))}
                    placeholder="Select..."
                    formatCreateLabel={(userInput) => userInput}
                  />

                  <ErrorMessage name="company" component={FormError} />
                </FormInputGroup>

                <Button
                  type="submit"
                  disabled={isSubmitting || !(isValid && dirty)}
                  loading={isSubmitting}
                  title="Save"
                />
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  );
}
