import Axios from '../../services/Axios';

import AddCompanyModal from '../../components/modal/AddCompanyModal';

import Block from '../../components/ui/Block';
import Button from '../../components/ui/Button';
import Datatable from '../../components/datatable';
import Card from '../../components/ui/Card';
import FormInput from '../../components/ui/FormInput';
import FormInputGroup from '../../components/ui/FormInputGroup';

import { useHistory } from 'react-router-dom';

import PageError from '../../components/ui/PageError';
import PageHeader from '../../components/dashboard/PageHeader';
import PageLoader from '../../components/ui/PageLoader';
import { mutate } from 'swr';

import { useState } from 'react';
import { useCompanies } from '../../data';

export default function UsersPage() {
  const history = useHistory();

  const [addCompanyModal, setAddCompanyModal] = useState(false);

  const [filterdData, setFilterdData] = useState(false);
  const [filterQuery, setFilterQuery] = useState('');

  const { companies, companiesLoading, companiesError } = useCompanies();

  const tableColumns = [
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Type',
      selector: 'companyType',
      format: (row) => {
        if (row.companyType) {
          return row.companyType.name;
        }
      },
      sortable: true,
    },
    {
      name: 'Country',
      selector: 'address',
      format: (row) => {
        if (row.address?.country) {
          return `${row.address.country}`;
        }
      },
      sortable: true,
    },
  ];

  if (companiesError) return <PageError />;
  if (companiesLoading) return <PageLoader />;

  const handleFilter = async (event) => {
    const { value } = event.target;

    setFilterQuery(value);

    if (value) {
      try {
        const { data: result } = await Axios.post(
          `${process.env.REACT_APP_API_URL}/api/companies/filter`,
          {
            query: value,
          }
        );

        setFilterdData(result.data);
      } catch (error) {}
    } else {
      setFilterdData(false);
    }
  };

  const FilterTable = () => (
    <FormInputGroup>
      <FormInput
        autoFocus
        type="text"
        placeholder="Search..."
        value={filterQuery}
        onChange={handleFilter}
      />
    </FormInputGroup>
  );

  return (
    <>
      <AddCompanyModal
        isOpen={addCompanyModal}
        title={'Add company'}
        onRequestClose={() => {
          setAddCompanyModal(false);
          mutate(`${process.env.REACT_APP_API_URL}/api/companies`);
        }}
      />

      <PageHeader title="Companies">
        <Button
          inline
          small
          icon="Plus"
          title="Add"
          onClick={() => {
            setAddCompanyModal(true);
          }}
        />
      </PageHeader>

      <Block>
        <Card inline full>
          <FilterTable />

          <Datatable
            columns={tableColumns}
            data={filterdData || companies}
            pointerOnHover
            progressPending={companiesLoading}
            fixedHeader
            noDataComponent="No companies found"
            onRowClicked={(company) => {
              // history.push(`/dashboard/company/${company.id}`);

              window.open(
                `/dashboard/company/${company.id}`,
                '_blank',
                'noopener,noreferrer'
              );
            }}
          />
        </Card>
      </Block>
    </>
  );
}
