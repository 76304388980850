import AuthService from '../../services/auth';
import LoginForm from '../../components/dashboard/LoginForm';
import PageLoader from '../../components/ui/PageLoader';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function LoginPage() {
  const history = useHistory();

  const { token } = useParams();

  useEffect(() => {
    async function logIn() {
      let loggedIn;
      if (token !== undefined) loggedIn = await AuthService.logIn(token);

      if (loggedIn) {
        history.push('/');
      } else {
        history.push('/login');
      }
    }

    if (token) logIn();
  }, [token, history]);

  if (token) return <PageLoader />;

  return (
    <>
      <section className="vh-100 d-flex align-items-center overlay-soft-dark">
        <Container>
          <Row className="justify-content-center">
            <Col
              lg={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="signin-inner mt-3 mt-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h1 className="mb-0 h3">Sign in</h1>
                </div>

                <LoginForm />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
