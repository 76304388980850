import Axios from '../services/Axios';
import axios from 'axios';

const jwt = require('jsonwebtoken');

const getToken = function () {
  return localStorage.getItem('token');
};

const isAuthenticated = function () {
  const token = getToken();

  if (!token) return false;

  try {
    const decodedToken = jwt.decode(token);

    if (decodedToken.exp < new Date() / 1000) return false;

    return true;
  } catch (error) {
    return false;
  }
};
const logIn = async function (token) {
  try {
    const response = await Axios.post(`auth/login/${token}`);

    if (response.data?.token) {
      await localStorage.setItem('token', response.data.token);

      axios.defaults.headers.common = {
        Authorization: `Bearer ${response.data.token}`,
      };
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

const logOut = function () {
  axios.defaults.headers.common = {};
  localStorage.removeItem('token');
};

const authFunctions = {
  getToken,
  isAuthenticated,
  logIn,
  logOut,
};

export default authFunctions;
