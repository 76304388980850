import { Loader } from 'react-feather';

import Container from 'react-bootstrap/Container';

export default function PageLoader() {
  return (
    <Container
      fluid
      className="flex min-vh-100"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Loader size={48} />
    </Container>
  );
}
