export default function PageHeader(props) {
  return (
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
      <div className="d-block mb-4 mb-md-0">
        {props.title && <h2 className="h4">{props.title}</h2>}

        {props.subTitle && <p className="mb-0">{props.subTitle}</p>}
      </div>
      <div className="btn-toolbar mb-2 mb-md-0">{props.children}</div>
    </div>
  );
}
