import PageHeader from '../../components/dashboard/PageHeader';
import { useLoggedInUser, useDashboard } from '../../data';

import Block from '../../components/ui/Block';

import { Users, Briefcase, Edit2 } from 'react-feather';

import Card from '../../components/ui/Card';
import Button from '../../components/ui/Button';

import MarkedNotesModal from '../../components/modal/MarkedNotesModal';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { useHistory } from 'react-router-dom';
import { useState } from 'react';

export default function DashboardPage() {
  const history = useHistory();

  const { loggedInUser, loggedInUserLoading } = useLoggedInUser();
  const { dashboard, dashboardLoading } = useDashboard();

  const [markedNotesModal, setMarkedNotesModal] = useState(false);

  return (
    <>
      {!loggedInUserLoading && !dashboardLoading && (
        <>
          <MarkedNotesModal
            isOpen={markedNotesModal}
            title={'Notes marked red'}
            notes={dashboard.notes}
            onRequestClose={() => {
              setMarkedNotesModal(false);
            }}
          />

          <PageHeader title={`Welcome, ${loggedInUser.email}!`} />

          <Block>
            <Row>
              <Col lg>
                <Card>
                  <Row>
                    <Col className="text-xl-center mb-3 d-flex align-items-center">
                      <div className="icon icon-shape icon-md icon-shape-primary rounded mr-4 mr-sm-0">
                        <Users strokeWidth="2" size={40} />
                      </div>
                    </Col>
                    <Col>
                      <div className="text-right">
                        <h2 className="h6 text-uppercase">People</h2>
                        <h3 className="mb-1">{dashboard.people}</h3>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Button
                        type="button"
                        small
                        onClick={() => {
                          history.push(`/dashboard/people`);
                        }}
                        icon="ExternalLink"
                        title="View all people"
                        className="w-100"
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>

              <Col lg>
                <Card>
                  <Row>
                    <Col className="text-xl-center mb-3 d-flex align-items-center">
                      <div className="icon icon-shape icon-md icon-shape-primary rounded mr-4 mr-sm-0">
                        <Briefcase strokeWidth="2" size={40} />
                      </div>
                    </Col>
                    <Col>
                      <div className="text-right">
                        <h2 className="h6 text-uppercase">Companies</h2>
                        <h3 className="mb-1">{dashboard.companies}</h3>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Button
                        type="button"
                        small
                        onClick={() => {
                          history.push(`/dashboard/companies`);
                        }}
                        icon="ExternalLink"
                        title="View all companies"
                        className="w-100"
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg>
                <Card>
                  <Row>
                    <Col className="text-xl-center mb-3 d-flex align-items-center">
                      <div className="icon icon-shape icon-md icon-shape-primary rounded mr-4 mr-sm-0">
                        <Edit2 strokeWidth="2" size={40} />
                      </div>
                    </Col>
                    <Col>
                      <div className="text-right">
                        <h2 className="h6 text-uppercase">Notes marked red</h2>
                        <h3 className="mb-1">{dashboard.notes.length}</h3>
                      </div>
                    </Col>
                  </Row>

                  {dashboard.notes.length > 0 && (
                    <Row>
                      <Col>
                        <Button
                          type="button"
                          small
                          onClick={() => {
                            setMarkedNotesModal(true);
                          }}
                          icon="ExternalLink"
                          title="View marked notes"
                          className="w-100"
                        />
                      </Col>
                    </Row>
                  )}
                </Card>
              </Col>

              <Col></Col>
            </Row>
          </Block>
        </>
      )}
    </>
  );
}
