import AddUserModal from '../../components/modal/AddUserModal';
import Axios from '../../services/Axios';
import Block from '../../components/ui/Block';
import Button from '../../components/ui/Button';
import Card from '../../components/ui/Card';
import Datatable from '../../components/datatable';
import FormInput from '../../components/ui/FormInput';
import FormInputGroup from '../../components/ui/FormInputGroup';
import PageError from '../../components/ui/PageError';
import PageHeader from '../../components/dashboard/PageHeader';
import PageLoader from '../../components/ui/PageLoader';
import { mutate } from 'swr';
import { useHistory } from 'react-router-dom';
import { usePeople } from '../../data';
import { useState } from 'react';

export default function UsersPage() {
  const history = useHistory();
  const [addUserModal, setAddUserModal] = useState(false);

  const [filterdData, setFilterdData] = useState(false);
  const [filterQuery, setFilterQuery] = useState('');

  const { people, peopleLoading, peopleError } = usePeople();

  const tableColumns = [
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'E-mail address',
      selector: 'email',
      sortable: true,
    },
    {
      name: 'Job title',
      selector: 'title',
      sortable: true,
    },
    {
      name: 'Company',
      selector: 'company',
      format: (row) => {
        if (row.company) {
          return row.company.name;
        }
      },
      sortable: true,
    },
  ];

  if (peopleError) return <PageError />;
  if (peopleLoading) return <PageLoader />;

  const handleFilter = async (event) => {
    const { value } = event.target;

    setFilterQuery(value);

    if (value) {
      try {
        const { data: result } = await Axios.post(
          `${process.env.REACT_APP_API_URL}/api/people/filter`,
          {
            query: value,
          }
        );

        setFilterdData(result.data);
      } catch (error) {}
    } else {
      setFilterdData(false);
    }
  };

  const FilterTable = () => (
    <FormInputGroup>
      <FormInput
        autoFocus
        type="text"
        placeholder="Search..."
        value={filterQuery}
        onChange={handleFilter}
      />
    </FormInputGroup>
  );

  return (
    <>
      <AddUserModal
        isOpen={addUserModal}
        title={'Add person'}
        onRequestClose={() => {
          setAddUserModal(false);
          mutate(`${process.env.REACT_APP_API_URL}/api/people`);
        }}
      />

      <PageHeader title="People">
        <Button
          inline
          small
          icon="Plus"
          title="Add"
          onClick={() => {
            setAddUserModal(true);
          }}
        />
      </PageHeader>

      <Block>
        <Card inline full>
          <FilterTable />

          <Datatable
            columns={tableColumns}
            data={filterdData || people}
            pointerOnHover
            progressPending={peopleLoading}
            fixedHeader
            noDataComponent="No people found"
            onRowClicked={(person) => {
              window.open(
                `/dashboard/person/${person.id}`,
                '_blank',
                'noopener,noreferrer'
              );
            }}
          />
        </Card>
      </Block>
    </>
  );
}
