import Button from '../../components/ui/Button';
import Modal from './index';

export default function Component(props) {
  return (
    <Modal {...props}>
      <div className="rounded p-3 mb-3 bg-gray-200 text-xl">Are you sure?</div>
      <Button
        type="submit"
        secondary
        title="Delete"
        autoFocus
        onClick={props.onSubmit}
      />
    </Modal>
  );
}
