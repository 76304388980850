import { ErrorMessage, Field, Form, Formik } from 'formik';

import Axios from '../../services/Axios';
import Button from '../ui/Button';
import FormError from '../ui/FormError';
import FormInput from '../ui/FormInput';
import FormInputGroup from '../ui/FormInputGroup';
import FormLabel from '../ui/FormLabel';
import FormSelect from '../ui/FormSelect';
import Modal from './index';
import toast from 'react-hot-toast';

export default function Component(props) {
  const colorOptions = [
    { color: 'Green', hex: '#05A677' },
    { color: 'Blue', hex: '#0948B3' },
    { color: 'Red', hex: '#DB504A' },
  ];

  return (
    <Modal {...props}>
      <Formik
        initialValues={props.note}
        validate={(values) => {
          const errors = {};

          if (!values.title) {
            errors.name = 'This field is required';
          }

          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await Axios.put(`api/note/${props.note.id}`, {
              title: values.title,
              text: values.text,
              color: values.color,
            });

            toast.success(`Note ${values.title} saved`);

            props.onRequestClose();
          } catch (error) {}
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <FormInputGroup>
              <FormLabel>Color</FormLabel>
              <Field as="select" name="color" component={FormSelect}>
                <option value="" disabled selected>
                  ...
                </option>

                {colorOptions.map((color) => {
                  return <option value={color.hex}>{color.color}</option>;
                })}
              </Field>
            </FormInputGroup>

            <FormInputGroup>
              <FormLabel>Title</FormLabel>
              <Field type="text" name="title" component={FormInput} />
              <ErrorMessage name="title" component={FormError} />
            </FormInputGroup>

            <FormInputGroup>
              <FormLabel>Description</FormLabel>
              <Field type="text" name="text" component={FormInput} />
            </FormInputGroup>

            <Button
              type="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
              title="Save"
            />
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
