import * as FeatherIcon from 'react-feather';

export default function FormInput({ field, form, ...props }) {
  const Icon = function () {
    const Icon = FeatherIcon[props.icon];

    return <Icon strokeWidth="2" size={16} />;
  };

  return (
    <>
      {props.icon && (
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <Icon />
            </span>
          </div>
          <input {...field} {...props} className="form-control" />
        </div>
      )}

      {!props.icon && <input {...field} {...props} className="form-control" />}
    </>
  );
}
