import { ErrorMessage, Field, Form, Formik, FieldArray } from 'formik';
import AuthService from '../../services/auth';

import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';

import { useRouteMatch, useHistory, Prompt } from 'react-router-dom';

import Axios from '../../services/Axios';
import Block from '../../components/ui/Block';

import Select from 'react-select';

import copy from 'copy-to-clipboard';

import FormError from '../../components/ui/FormError';
import FormInput from '../../components/ui/FormInput';
import FormInputGroup from '../../components/ui/FormInputGroup';
import FormLabel from '../../components/ui/FormLabel';

import Card from '../../components/ui/Card';
import Button from '../../components/ui/Button';

import DeleteModal from '../../components/modal/DeleteModal';
import PageError from '../../components/ui/PageError';
import PageHeader from '../../components/dashboard/PageHeader';
import PageLoader from '../../components/ui/PageLoader';
import { mutate } from 'swr';
import toast from 'react-hot-toast';
import { useState } from 'react';
import { usePerson, useCompanies, useLoggedInUser } from '../../data';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Moment from 'react-moment';

import Datatable from '../../components/datatable';
import AddNoteModal from '../../components/modal/AddNoteModal';
import EditNoteModal from '../../components/modal/EditNoteModal';

export default function UsersPage() {
  const history = useHistory();

  const { params } = useRouteMatch();

  const { loggedInUser, loggedInUserLoading } = useLoggedInUser();

  const [deleteModal, setDeleteModal] = useState(false);
  const [addNoteModal, setAddNoteModal] = useState(false);
  const [editNoteModal, setEditNoteModal] = useState(false);
  const [selectedNote, setSelectedNote] = useState(false);

  const { person, personLoading, personError } = usePerson(params.personId);
  const { companies, companiesLoading, companiesError } = useCompanies();

  if (personError || companiesError) return <PageError />;
  if (personLoading || companiesLoading || loggedInUserLoading)
    return <PageLoader />;

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const ExpandableComponent = ({ data }) => (
    <div className="p-3">{data.text}</div>
  );

  const SelectField = ({ field, colors, form, ...props }) => {
    return (
      <Select
        placeholder="Select..."
        isClearable
        noOptionsMessage={() => 'No company found'}
        onChange={(e) => form.setFieldValue('company', e)}
        {...props}
      />
    );
  };

  return (
    <>
      <AddNoteModal
        isOpen={addNoteModal}
        title={'Add note'}
        personId={person.id}
        onRequestClose={() => {
          setAddNoteModal(false);
          mutate(`${process.env.REACT_APP_API_URL}/api/person/${person.id}`);
        }}
      />
      <EditNoteModal
        isOpen={editNoteModal}
        title={'Edit note'}
        note={selectedNote}
        onRequestClose={() => {
          setEditNoteModal(false);
          mutate(`${process.env.REACT_APP_API_URL}/api/person/${person.id}`);

          setSelectedNote(false);
        }}
      />

      <DeleteModal
        isOpen={deleteModal}
        title={'Delete person'}
        onRequestClose={() => {
          setDeleteModal(false);
        }}
        onSubmit={async () => {
          try {
            await Axios.delete(`api/person/${person.id}`);

            toast.success(`Person ${person.name} deleted`);

            mutate(`${process.env.REACT_APP_API_URL}/api/people`);

            setDeleteModal(false);

            history.push(`/dashboard/people`);
          } catch (error) {}
        }}
      />

      <PageHeader title={`Person: ${person.name}`}>
        <Button
          inline
          small
          icon="Plus"
          title="Add note"
          onClick={() => {
            setAddNoteModal(true);
          }}
        />

        {loggedInUser.role === 'Admin' && (
          <Button
            inline
            small
            secondary
            icon="Trash"
            title="Delete person"
            onClick={() => {
              setDeleteModal(true);
            }}
            className="ml-2"
          />
        )}
      </PageHeader>

      <Block>
        <Formik
          initialValues={person}
          enableReinitialize
          validate={(values) => {
            const errors = {};

            if (!values.name) {
              errors.name = 'This field is required';
            }

            if (
              values.email &&
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Invalid email address';
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            try {
              setSubmitting(true);

              await Axios.put(`api/person/${person.id}`, {
                name: values.name,
                title: values.title,
                phoneNumber: values.phoneNumber,
                email: values.email,
                companyId: values.company ? values.company.id : null,
                address: {
                  address: values.address?.address,
                  postalCode: values.address?.postalCode,
                  city: values.address?.city,
                  country: values.address?.country,
                },
                info: values.info,
              });

              toast.success(`Person ${values.name} saved`);

              resetForm({ values });
            } catch (error) {}
          }}
        >
          {({ values, isSubmitting, isValid, dirty, submitForm }) => (
            <>
              <Prompt
                when={dirty}
                message="There are unsaved changes. Are you sure you want to leave?"
              />

              <Card>
                <Row>
                  <Col>
                    <Button
                      disabled={!dirty || !isValid || isSubmitting}
                      loading={isSubmitting}
                      title="Save"
                      onClick={() => {
                        submitForm();
                      }}
                    />
                  </Col>
                </Row>
              </Card>

              <Form className="w-100">
                <Row>
                  <Col xl={7}>
                    <Card>
                      <Row>
                        <Col xl={6}>
                          <FormInputGroup>
                            <FormLabel>Name</FormLabel>
                            <Field
                              type="text"
                              name="name"
                              component={FormInput}
                            />
                            <ErrorMessage name="name" component={FormError} />
                          </FormInputGroup>
                        </Col>

                        <Col xl={6}>
                          <FormInputGroup>
                            <FormLabel>Job title</FormLabel>
                            <Field
                              type="text"
                              name="title"
                              component={FormInput}
                            />
                          </FormInputGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col xl={6}>
                          <FormInputGroup>
                            <FormLabel>
                              <a href={`tel:${values.phoneNumber}`}>
                                Phone number
                              </a>
                            </FormLabel>
                            <Field
                              type="tel"
                              name="phoneNumber"
                              component={FormInput}
                            />
                          </FormInputGroup>
                        </Col>

                        <Col xl={6}>
                          <FormInputGroup>
                            <FormLabel>
                              <a href={`mailto:${values.email}`}>
                                E-mail address
                              </a>
                            </FormLabel>
                            <Field
                              type="email"
                              name="email"
                              component={FormInput}
                            />
                            <ErrorMessage name="email" component={FormError} />
                          </FormInputGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col xl={12}>
                          <h2 className="h5 mt-5 mb-4">Company</h2>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <Field
                            component={SelectField}
                            options={companies}
                            defaultValue={values.company}
                            name="company"
                            getOptionLabel={(option) => `${option.name}`}
                            getOptionValue={(option) => `${option.id}`}
                          />
                        </Col>

                        {values.company && (
                          <Col xs={2}>
                            <Button
                              small
                              icon="ExternalLink"
                              className="h-100 w-100"
                              onClick={() => {
                                history.push(
                                  `/dashboard/company/${values.company.id}`
                                );
                              }}
                            />
                          </Col>
                        )}
                      </Row>

                      <Row>
                        <Col xl={12}>
                          <h2 className="h5 mt-5 mb-4">Address</h2>
                        </Col>
                      </Row>

                      <Row>
                        <Col xl={6}>
                          <FormInputGroup>
                            <FormLabel>Address</FormLabel>
                            <Field
                              type="text"
                              name="address.address"
                              component={FormInput}
                            />
                          </FormInputGroup>
                        </Col>
                        <Col xl={6}>
                          <FormInputGroup>
                            <FormLabel>Postal code</FormLabel>
                            <Field
                              type="text"
                              name="address.postalCode"
                              component={FormInput}
                            />
                          </FormInputGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl={6}>
                          <FormInputGroup>
                            <FormLabel>City</FormLabel>
                            <Field
                              type="text"
                              name="address.city"
                              component={FormInput}
                            />
                          </FormInputGroup>
                        </Col>
                        <Col xl={6}>
                          <FormInputGroup>
                            <FormLabel>Country</FormLabel>
                            <Field
                              type="text"
                              name="address.country"
                              component={FormInput}
                            />
                          </FormInputGroup>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <FieldArray name="info">
                        {({ remove, push }) => (
                          <>
                            <Row>
                              <Col sm={6}>
                                <h2 className="h5">Fields</h2>
                              </Col>

                              <Col sm={6} className="text-right">
                                <Button
                                  type="button"
                                  small
                                  onClick={() =>
                                    push({ label: '', content: '' })
                                  }
                                  title="Add field"
                                />
                              </Col>
                            </Row>

                            {values.info?.length > 0 && (
                              <Row>
                                <Col>
                                  {values.info.map((info, index) => (
                                    <Row key={index}>
                                      <Col>
                                        <div className="rounded px-4 py-2 mt-4 bg-soft">
                                          <FormInputGroup
                                            style={{ marginBottom: '0' }}
                                          >
                                            <Field
                                              type="text"
                                              name={`info.${index}.label`}
                                              component={FormInput}
                                              style={{
                                                backgroundColor: 'transparent',
                                                border: 'none',
                                                padding: '0',
                                                margin: '0',
                                                outline: 'none',
                                                boxShadow: 'none',
                                              }}
                                              placeholder="Label..."
                                            />
                                          </FormInputGroup>

                                          <FormInputGroup>
                                            <div className="d-flex flex-row">
                                              <Field
                                                type="text"
                                                name={`info.${index}.content`}
                                                component={FormInput}
                                              />

                                              <Button
                                                inline
                                                small
                                                icon="Copy"
                                                className="ml-2"
                                                onClick={() => {
                                                  copy(info.content);
                                                  toast.success(`Copied`);
                                                }}
                                              />

                                              <Button
                                                inline
                                                small
                                                icon="Trash2"
                                                className="ml-2"
                                                onClick={() => {
                                                  remove(index);
                                                }}
                                              />
                                            </div>
                                          </FormInputGroup>
                                        </div>
                                      </Col>
                                    </Row>
                                  ))}
                                </Col>
                              </Row>
                            )}
                          </>
                        )}
                      </FieldArray>
                    </Card>

                    <Card>
                      <h2 className="h5">Files</h2>
                      <p>Caution: files are publicly accessible!</p>

                      <Dropzone
                        getUploadParams={({ file, meta }) => {
                          const body = new FormData();
                          body.append('file', file);
                          body.append('personId', person.id);

                          return {
                            url: `${process.env.REACT_APP_API_URL}/api/upload`,
                            headers: {
                              Authorization: `Bearer ${AuthService.getToken()}`,
                            },
                            body,
                          };
                        }}
                        onChangeStatus={(
                          { meta, file, xhr },
                          status,
                          allFiles
                        ) => {
                          if (xhr?.response) {
                            const { fileName } = JSON.parse(xhr.response);

                            if (status === 'done') {
                              allFiles.forEach((file) => {
                                if (file.name === fileName) file.remove();
                              });

                              mutate(
                                `${process.env.REACT_APP_API_URL}/api/person/${person.id}`
                              );
                            }
                          }
                        }}
                        multiple={true}
                        canCancel={false}
                        inputContent="Drop a file or click to browse"
                        maxSizeBytes={52428800}
                        autoUpload={true}
                      />

                      {person.files.length > 0 && (
                        <Datatable
                          columns={[
                            {
                              name: 'Filename',
                              selector: 'fileName',
                              format: (row) => {
                                return row.fileName.substring(11);
                              },
                              sortable: false,
                            },
                            {
                              selector: 'fileName',
                              width: '80px',
                              format: (row) => {
                                return (
                                  <Button
                                    inline
                                    small
                                    icon="Copy"
                                    onClick={() => {
                                      copy(
                                        `${process.env.REACT_APP_API_URL}/uploads/${row.fileName}`
                                      );
                                      toast.success(`URL copied`);
                                    }}
                                  />
                                );
                              },
                              sortable: false,
                            },
                          ]}
                          noHeader
                          data={person.files}
                          pointerOnHover
                          onRowClicked={(file) => {
                            openInNewTab(
                              `${process.env.REACT_APP_API_URL}/uploads/${file.fileName}`
                            );
                          }}
                        />
                      )}
                    </Card>
                  </Col>
                </Row>
              </Form>
            </>
          )}
        </Formik>

        <div
          role="separator"
          className="dropdown-divider mt-2 mb-4 border-gray-500"
        ></div>

        <Row>
          <Col>
            <Card>
              <Row>
                <Col sm={6}>
                  <h2 className="h5">Notes</h2>
                </Col>

                <Col sm={6} className="text-right">
                  <Button
                    inline
                    small
                    icon="Plus"
                    title="Add note"
                    onClick={() => {
                      setAddNoteModal(true);
                    }}
                  />
                </Col>
              </Row>

              <Datatable
                columns={[
                  {
                    selector: 'color',
                    width: '42px',
                    format: (row) => {
                      return (
                        <div style={{ display: 'flex' }}>
                          <span
                            className="shape-xs rounded-circle"
                            style={{
                              backgroundColor: row.color
                                ? row.color
                                : '#000000',
                            }}
                          ></span>
                        </div>
                      );
                    },
                    sortable: false,
                  },
                  {
                    selector: 'createdAt',
                    format: (row) => {
                      return (
                        <Moment
                          fromNowDuring={604800000}
                          format="DD MMMM YYYY[,] HH:mm"
                        >
                          {row.createdAt}
                        </Moment>
                      );
                    },
                    sortable: false,
                  },
                  {
                    name: 'Title',
                    selector: 'title',
                    sortable: false,
                  },
                ]}
                noHeader
                data={person.notes}
                progressPending={personLoading}
                expandableRows
                expandableRowsComponent={<ExpandableComponent />}
                expandableRowDisabled={(data) => {
                  return !data.text;
                }}
                noDataComponent="No notes found"
                pointerOnHover
                onRowClicked={(note) => {
                  setSelectedNote(note);
                  setEditNoteModal(true);
                }}
              />
            </Card>
          </Col>
        </Row>
      </Block>
    </>
  );
}
