import AuthService from '../../services/auth';
import * as FeatherIcon from 'react-feather';

import Nav from 'react-bootstrap/Nav';

import MenuLink from '../../components/ui/MenuLink';
import Button from '../../components/ui/Button';

import { useHistory } from 'react-router-dom';
import { useLoggedInUser } from '../../data';

export default function Sidebar(props) {
  const history = useHistory();

  const Icon = function (props) {
    const Icon = FeatherIcon[props.loading ? 'Loader' : props.icon];

    return <Icon strokeWidth="2" size={16} />;
  };

  const sidebarItems = [
    [
      {
        title: 'Dashboard',
        href: '/dashboard',
        icon: 'Activity',
      },
    ],
    [
      {
        title: 'People',
        href: '/dashboard/people',
        activeFilter: '/dashboard/people',
        icon: 'Users',
      },
      {
        title: 'Companies',
        href: '/dashboard/companies',
        activeFilter: '/dashboard/compan',
        icon: 'Briefcase',
      },
    ],
  ];

  const { loggedInUser, loggedInUserLoading } = useLoggedInUser();

  return (
    <>
      {!loggedInUserLoading && (
        <Nav
          className={`sidebar d-md-block bg-primary text-white collapse px-4 ${
            props.showSidebar ? 'show' : ''
          }`}
        >
          {!props.showSidebar && (
            <div
              className="bg-white"
              style={{
                margin: '-1.5rem',
                padding: '3rem 1.5rem 1.5rem 1.5rem',
              }}
            >
              <img src="/assets/logo.svg" alt="" />
            </div>
          )}

          <div
            className={`sidebar-sticky mt-4 pt-4 ${
              props.showSidebar ? 'w-100' : 'mx-auto'
            }`}
          >
            <div className="user-card d-flex align-items-left justify-content-left pb-4">
              <div className="d-flex align-items-left">
                <div className="d-block">
                  <h2 className="h6">{loggedInUser.email}</h2>
                  <h2 className="h6">{loggedInUser.role}</h2>
                  <Button
                    secondary
                    xs
                    title="Sign out"
                    onClick={() => {
                      history.push('/logout');
                    }}
                  />
                </div>
              </div>
            </div>

            <ul className="nav flex-column mt-3">
              {sidebarItems.map((block) => (
                <>
                  {block.map((sidebarItem, sidebarItemIndex) => (
                    <MenuLink
                      href={sidebarItem.href}
                      activeFilter={sidebarItem.activeFilter}
                      toggleSidebar={props.toggleSidebar}
                    >
                      {sidebarItem.icon ? (
                        <>
                          <span className="sidebar-icon">
                            <Icon icon={sidebarItem.icon} />
                          </span>
                          <span>{sidebarItem.title}</span>
                        </>
                      ) : (
                        <> {sidebarItem.title}</>
                      )}
                    </MenuLink>
                  ))}

                  <li
                    role="separator"
                    className="dropdown-divider mt-3 mb-3 border-blue"
                  ></li>
                </>
              ))}
            </ul>
          </div>
        </Nav>
      )}
    </>
  );
}
