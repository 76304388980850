import { ErrorMessage, Field, Form, Formik } from 'formik';

import Axios from '../../services/Axios';
import Button from '../../components/ui/Button';
import FormError from '../../components/ui/FormError';
import FormInput from '../../components/ui/FormInput';
import FormInputGroup from '../../components/ui/FormInputGroup';

import { useState } from 'react';

export default function LoginForm() {
  const [succes, setSuccess] = useState(false);

  return (
    <>
      {!succes && (
        <Formik
          initialValues={{ email: '' }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = 'This field is required';
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Invalid email address';
            }
            return errors;
          }}
          onSubmit={async (values) => {
            try {
              await Axios.post('auth/login', { email: values.email });
              setSuccess(true);
            } catch (error) {}
          }}
        >
          {({ isSubmitting, isValid, dirty }) => (
            <Form>
              <FormInputGroup>
                <Field
                  name="email"
                  autoFocus
                  component={FormInput}
                  placeholder="E-mail address"
                  icon="User"
                />

                <ErrorMessage name="email" component={FormError} />
              </FormInputGroup>

              <Button
                type="submit"
                disabled={isSubmitting || !(isValid && dirty)}
                loading={isSubmitting}
                title="Sign in"
                className="btn-block"
              />
            </Form>
          )}
        </Formik>
      )}

      {succes && <div className="text-center">Please, check your inbox</div>}
    </>
  );
}
