import DataTable from 'react-data-table-component';
import { Loader } from 'react-feather';

export default function Datatable(props) {
  return (
    <div className="w-100">
      <DataTable
        highlightOnHover={true}
        paginationComponentOptions={{
          noRowsPerPage: false,
          selectAllRowsItem: false,
        }}
        pagination={props.data?.length > 20 ? true : false}
        customStyles={{
          header: {
            style: {
              fontSize: '16px',
            },
          },
          headCells: {
            style: {
              fontSize: '16px',
            },
          },
          contextMenu: {
            style: {
              fontSize: '16px',
            },
          },
          rows: {
            style: {
              fontSize: '16px',
            },
          },
          pagination: {
            style: {
              fontSize: '16px',
            },
          },
        }}
        progressComponent={<Loader size={48} className="animate-spin" />}
        {...props}
      />
    </div>
  );
}
