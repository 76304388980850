import Modal from './index';

import Datatable from '../../components/datatable';
import { useHistory } from 'react-router-dom';

export default function Component(props) {
  const history = useHistory();

  const tableColumns = [
    {
      name: 'Belongs to',
      selector: 'title',
      format: (row) => {
        if (row.companyId) {
          return `Company: ${row.company.name}`;
        } else if (row.personId) {
          return `Person: ${row.person.name}`;
        }
      },
      sortable: true,
    },
    {
      name: 'Title',
      selector: 'title',
      sortable: true,
    },
  ];

  return (
    <Modal large {...props}>
      <Datatable
        columns={tableColumns}
        data={props.notes}
        pointerOnHover
        fixedHeader
        noDataComponent="No notes found"
        onRowClicked={(note) => {
          if (note.companyId) {
            history.push(`/dashboard/company/${note.companyId}`);
          } else if (note.personId) {
            history.push(`/dashboard/person/${note.personId}`);
          }
        }}
      />
    </Modal>
  );
}
