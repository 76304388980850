import Container from 'react-bootstrap/Container';

import Card from '../ui/Card';

export default function PageError(props) {
  return (
    <Container className="mt-4">
      <Card inline>
        {props.children
          ? props.children
          : 'Unfortunately, something went wrong while retrieving this page'}
      </Card>
    </Container>
  );
}
