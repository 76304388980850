import Card from 'react-bootstrap/Card';

export default function Component(props) {
  return (
    <Card className="border-light shadow-sm mb-4">
      <Card.Body>{props.children}</Card.Body>
    </Card>

    // <Card className="border-light shadow-sm mb-4">
    // <Card.Body>
    //   <div className="row d-block d-xl-flex align-items-center">
    //     <div
    //       className={`col-12 mb-3 mb-xl-0 d-flex ${
    //         props.center
    //           ? 'align-items-center text-xl-center justify-content-xl-center'
    //           : ''
    //       }`}
    //     >
    //       {props.children}
    //     </div>
    //   </div>
    // </Card.Body>
    // </Card>
  );
}
