import Modal from 'react-modal';

export default function Component(props) {
  const modalStyles = {
    overlay: {
      backgroundColor: 'rgb(0 0 0 / 75%)',
      backdropFilter: 'blur(4px)',
      WebkitBackdropFilter: 'blur(4px)',
      zIndex: '999',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      border: 'none',
      borderRadius: '.3rem',
      outline: 'none',
      padding: '0',
      width: props.large ? '1160px' : '600px',
      maxWidth: 'calc(100vw - 2.5rem)',
      maxHeight: 'calc(100vh - 5rem)',
      overflow: 'visible',
    },
  };

  Modal.setAppElement('#root');

  return (
    <Modal style={modalStyles} {...props}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">{props.title}</h5>
        </div>
        <div className="modal-body">{props.children}</div>
      </div>
    </Modal>
  );
}
