import * as FeatherIcon from 'react-feather';

export default function Button(props) {
  const { className } = props;

  const Icon = function () {
    const Icon = FeatherIcon[props.loading ? 'Loader' : props.icon];

    return <Icon strokeWidth="2" size={16} />;
  };

  return (
    <button
      {...props}
      type={props.type ? props.type : 'button'}
      className={`btn ${props.secondary ? 'btn-secondary' : 'btn-primary'} ${
        props.xs ? 'btn-xs' : ''
      } ${props.small ? 'btn-sm' : ''} ${className ? className : ''}`}
    >
      {props.icon && <Icon />}

      {props.title && (
        <span className={`${props.icon ? 'ml-2' : ''}`}>{props.title}</span>
      )}
    </button>
  );
}
