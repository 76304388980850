import Axios from '../services/Axios';
import useSWR from 'swr';

const swrFetcher = (url) => Axios.get(url).then((res) => res.data);

export function useApiStatus() {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_API_URL}/api/status`,
    swrFetcher
  );

  return {
    apiStatus: data,
    apiLoading: !error && !data,
    apiError: error,
  };
}

export function useLoggedInUser() {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_API_URL}/api/me`,
    swrFetcher
  );

  return {
    loggedInUser: data?.data,
    loggedInUserLoading: !error && !data,
    loggedInUserError: error,
  };
}

export function useDashboard() {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_API_URL}/api/dashboard`,
    swrFetcher
  );

  return {
    dashboard: data?.data,
    dashboardLoading: !error && !data,
    dashboardError: error,
  };
}

export function usePerson(id) {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_API_URL}/api/person/${id}`,
    swrFetcher
  );

  return {
    person: data?.data,
    personLoading: !error && !data,
    personError: error,
  };
}

export function usePeople() {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_API_URL}/api/people`,
    swrFetcher
  );

  return {
    people: data?.data,
    peopleLoading: !error && !data,
    peopleError: error,
  };
}

export function useCompany(id) {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_API_URL}/api/company/${id}`,
    swrFetcher
  );

  return {
    company: data?.data,
    companyLoading: !error && !data,
    companyError: error,
  };
}

export function useCompanies(filter) {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_API_URL}/api/companies`,
    swrFetcher
  );

  return {
    companies: data?.data,
    companiesLoading: !error && !data,
    companiesError: error,
  };
}

export function useCompanyTypes() {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_API_URL}/api/company-types`,
    swrFetcher
  );

  return {
    companyTypes: data?.data,
    companyTypesLoading: !error && !data,
    companyTypesError: error,
  };
}
