import { useState } from 'react';

import { Route, Switch } from 'react-router-dom';

import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';

import PageLoader from '../../components/ui/PageLoader';
import PageError from '../../components/ui/PageError';

import DashboardPage from '../dashboard/DashboardPage';

import Sidebar from '../../components/dashboard/Sidebar';

import PeoplePage from '../dashboard/PeoplePage';
import PersonPage from '../dashboard/PersonPage';

import CompaniesPage from '../dashboard/CompaniesPage';
import CompanyPage from '../dashboard/CompanyPage';

import { useApiStatus } from '../../data';

export default function App() {
  const { apiLoading, apiError } = useApiStatus();
  const [showSidebar, toggleSidebar] = useState(false);

  if (apiLoading) return <PageLoader />;
  if (apiError) return <PageError screen />;

  return (
    <>
      <Nav className="navbar navbar-dark navbar-theme-primary col-12 d-md-none">
        <div className="d-flex align-items-center">
          <button
            className="navbar-toggler d-md-none collapsed"
            type="button"
            onClick={toggleSidebar}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
      </Nav>

      <Container fluid className="bg-soft min-vh-100">
        <Sidebar showSidebar={showSidebar} toggleSidebar={toggleSidebar} />
        <main className="content">
          <Switch>
            <Route exact path="/dashboard" component={DashboardPage} />

            <Route exact path="/dashboard/people" component={PeoplePage} />
            <Route
              exact
              path="/dashboard/person/:personId"
              component={PersonPage}
            />

            <Route
              exact
              path="/dashboard/companies"
              component={CompaniesPage}
            />
            <Route
              exact
              path="/dashboard/company/:companyId"
              component={CompanyPage}
            />
          </Switch>
        </main>
      </Container>
    </>
  );
}
