import { ErrorMessage, Field, Form, Formik } from 'formik';

import Axios from '../../services/Axios';
import Button from '../../components/ui/Button';
import FormError from '../../components/ui/FormError';
import FormInput from '../../components/ui/FormInput';
import FormInputGroup from '../../components/ui/FormInputGroup';
import FormLabel from '../../components/ui/FormLabel';
import Modal from './index';
import toast from 'react-hot-toast';

export default function Component(props) {
  return (
    <Modal {...props}>
      <Formik
        initialValues={{ name: '', email: '', company: '' }}
        validate={(values) => {
          const errors = {};

          if (!values.name) {
            errors.name = 'This field is required';
          }

          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await Axios.post('api/person', {
              name: values.name,
            });

            toast.success(`Person ${values.name} added`);

            props.onRequestClose();
          } catch (error) {}
        }}
      >
        {({ isSubmitting, isValid, dirty }) => (
          <Form>
            <FormInputGroup>
              <FormLabel>Name</FormLabel>
              <Field type="text" name="name" autoFocus component={FormInput} />
              <ErrorMessage name="name" component={FormError} />
            </FormInputGroup>

            <Button
              type="submit"
              disabled={isSubmitting || !(isValid && dirty)}
              loading={isSubmitting}
              title="Save"
            />
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
